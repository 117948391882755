import { Injectable } from '@angular/core';

import { Actions, createEffect } from '@ngrx/effects';

import { AuthService } from '@views/misc/auth/auth.service';

import * as actions from './notifications.actions';
import { NotificationsService } from './notifications.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private authService: AuthService,
  ) {}

  fetchList$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.notificationsService.fetchList(action.payload),
      startAction: actions.notificationsFetchListStart,
      successAction: actions.notificationsFetchListSuccess,
      failAction: actions.notificationsFetchListFail,
    }),
  );

  fetchUnreadCount$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.notificationsService.fetchUnreadNotificationsCount(),
      startAction: actions.notificationsFetchUnreadCountStart,
      successAction: actions.notificationsFetchUnreadCountSuccess,
      failAction: actions.notificationsFetchUnreadCountFail,
    }),
  );

  updateItem$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.notificationsService.updateItem(action.payload.notificationId),
      startAction: actions.notificationsUpdateItemStart,
      successAction: actions.notificationsUpdateItemSuccess,
      failAction: actions.notificationsUpdateItemFail,
    }),
  );

  updateAll$ = createEffect(() =>
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.notificationsService.updateAll(),
      startAction: actions.notificationsMarkAllAsReadStart,
      successAction: actions.notificationsMarkAllAsReadSuccess,
      failAction: actions.notificationsMarkAllAsReadFail,
    }),
  );
}
